import {ResolveFn, Router} from "@angular/router";
import {ISidebarItem} from "../../oex-ui-kit/components/sidebar/sidebar.component";
import {inject} from "@angular/core";
import {AuthService} from "./auth.service";
import {APIService} from "./api.service";
import {of} from "rxjs";

export const userSidebarItems: ResolveFn<ISidebarItem[]> = () => {
  const auth = inject(AuthService);
  const api = inject(APIService);
  const router = inject(Router);

  /*
    const venture: ISidebarItem = {label: 'Venture fund', url: 'tracks/venture-fund'};
    const growth: ISidebarItem = {label: 'Growth toolkit', url: 'tracks/growth'};
    const incubator: ISidebarItem = {label: 'Incubator', url: 'tracks/incubator'};
  */
  // /${auth.user?.startup?.slug}
  const items: ISidebarItem[] = [
      ...[
        {label: 'Overview', url: 'overview'},
        {label: 'Edit Startup', url: 'edit'}
      ],
      ...(auth.user.isStartupAdmin ? [{label: 'Members', url: 'members'}] : []),
      ...[
        {label: 'My Profile', url: 'profile'},
        {label: 'Contact', url: 'contact'}
      ],
  ];

  return of(items);
  /*return api.getTracks()
    .then(resp => {
      venture.counter = resp.track.find(t => t.slug === 'venture-fund')?.startup_qt?.toString() || '';
      incubator.counter = resp.track.find(t => t.slug === 'incubator')?.startup_qt?.toString() || '';
      growth.counter = resp.track.find(t => t.slug === 'growth-toolkit')?.startup_qt?.toString() || '';

      return items;
    });*/
}
