import {Route, Routes} from '@angular/router';
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {NotFoundPageComponent} from "./pages/not-found-page/not-found-page.component";
import {PortalPageComponent} from "../oex-ui-kit/components/portal-page/portal-page.component";
import {
  authAdminResolver,
  authUserLoggedResolver,
  startupEditorResolver,
  startupOverviewResolver
} from "./services/auth.service";
import {adminSidebarItems} from "./services/admin-sidebar.resolver";
import {IMeta} from "../oex-ui-kit/services/meta.service";
import {userSidebarItems} from "./services/user-sidebar.resolver";
import {startupCanDeactivateResolver} from "./pages/startup-editor-page/startup-editor-page.component";
import {resolveStartupData, resolveStartupTracks} from "./services/startup-data.resolver";
import {resolveStatusesData} from "./services/status.service";
import {getDictionariesResolver} from "./services/dictionary.service";
import {
  statusesResolver,
  trackAccountManagerResolver,
  trackPageModelResolver,
  trackYearsResolver
} from "./services/track-data.service";
import {resolveUserProfileData} from "./pages/profile-editor-page/profile-editor-page.component";
import {resolveStartupHashData} from "./pages/link-startup-page/link-startup-page.component";
import {resolveAdminNewsletter} from './pages/admin-newsletter/admin-newsletter.component';
import {portalMembersCanActivate, resolveStartupMembers} from './pages/members-page/members-page.component';
import {resolveAdminUsers} from './pages/user-management-page/user-management-page.component';


const meta: IMeta = {
  image: '/assets/img/logo.svg',
  "image:secure_url": '/assets/img/logo.svg',
  description: ''
}

const overviewDictionaries = getDictionariesResolver([
  'user_deal_lead',
  'user_responsible',
  'category',
  'program',
  'country',
  'industry',
  'product',
  'stage',
  'type',
  'incubator_batch',
  'manager'
]);

const startupEditorDictionaries = getDictionariesResolver([
  'user_deal_lead',
  'user_responsible',
  'user_assigner',
  'category',
  'program',
  'country',
  'industry',
  'product',
  'stage',
  'type',
  'incubator_batch'
]);

const overviewResolves = {
  startup: resolveStartupData,
  tracks: resolveStartupTracks,
  statuses: resolveStatusesData,
  dict: overviewDictionaries
};

const startupEditorResolvers = {
  startup: resolveStartupData,
  tracks: resolveStartupTracks,
  statuses: resolveStatusesData,
  dict: startupEditorDictionaries
};

const startupEditorRoute: Route = {
  loadComponent: () => import('./pages/startup-editor-page/startup-editor-page.component').then(m => m.StartupEditorPageComponent),
  title: 'Edit Startup',
  resolve: startupEditorResolvers,
  canActivate: [startupEditorResolver],
  canDeactivate: [startupCanDeactivateResolver],
  runGuardsAndResolvers: 'pathParamsChange'
}

const startupOverviewRoute: Route = {
  loadComponent: () => import('./pages/overview-page/overview-page.component').then(m => m.OverviewPageComponent),
  title: 'Startup Overview',
  resolve: overviewResolves,
  canActivate: [startupOverviewResolver],
  runGuardsAndResolvers: 'pathParamsChange'
}

const portalRoutes: Routes = [
  {
    path: '',
    redirectTo: '/portal/overview',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    redirectTo: '/portal/overview/',
    pathMatch: 'full'
  },
  {
    path: 'overview/:startup',
    ...startupOverviewRoute
  },
  {path: 'edit', redirectTo: '/portal/edit/', pathMatch: 'full'},
  {
    path: 'edit/:startup',
    ...startupEditorRoute
  },
  {
    title: 'Members',
    path: 'members',
    loadComponent: () => import('./pages/members-page/members-page.component').then(m => m.MembersPageComponent),
    canActivate: [portalMembersCanActivate],
    resolve: {
      model: resolveStartupMembers
    }
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile-editor-page/profile-editor-page.component').then(m => m.ProfileEditorPageComponent),
    resolve: {
      profile: resolveUserProfileData
    },
    title: 'My Profile'
  },
  {
    path: 'contact',
    loadComponent: () => import('./pages/contact-page/contact-page.component').then(m => m.ContactPageComponent),
    title: 'Contact'
  }
];

const adminRoutes: Routes = [
  {path: '', redirectTo: 'track/venture-fund', pathMatch: 'full'},
  {
    path: 'track/:track',
    title: 'Tracks',
    loadComponent: () => import('./pages/track-page/track-page.component').then(m => m.TrackPageComponent),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      model: trackPageModelResolver,
      years: trackYearsResolver,
      accountManager: trackAccountManagerResolver,
      statuses: statusesResolver,
      dict: getDictionariesResolver(['industry', 'location', 'user_deal_lead', 'stage'])
    }
  },
  {
    path: 'track/:track/:startup/overview',
    title: 'Overview',
    ...startupOverviewRoute
  },
  {
    path: 'track/:track/:startup/edit',
    ...startupEditorRoute
  },
  {
    path: 'newsletter',
    title: 'Newsletter',
    loadComponent: () => import('./pages/admin-newsletter/admin-newsletter.component').then(m => m.AdminNewsletterComponent),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      model: resolveAdminNewsletter
    },
  },
  {
    path: 'users',
    title: 'User management',
    loadComponent: () => import('./pages/user-management-page/user-management-page.component').then(m => m.UserManagementPageComponent),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      model: resolveAdminUsers
    },
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile-editor-page/profile-editor-page.component').then(m => m.ProfileEditorPageComponent),
    resolve: {
      profile: resolveUserProfileData
    },
    title: 'My Profile'
  }
]

export const routes: Routes = [
  {path: '', component: HomePageComponent, title: 'Home'},
  {
    path: 'link',
    loadComponent: () => import('./pages/link-startup-page/link-startup-page.component').then(m => m.LinkStartupPageComponent),
    title: 'Link your account to startup',
    resolve: {
      startup: resolveStartupHashData
    },
    data: {
      hideFooter: true
    }
  },
  {
    path: 'thank-you',
    loadComponent: () => import('./pages/thank-you-page/thank-you-page.component').then(m => m.ThankYouPageComponent),
    title: 'Thank you',
    data: {
      hideFooter: true
    }
  },
  /*{
    path: 'admin',
    loadComponent: () => import('./pages/admin-page/admin-page.component').then(m => m.AdminPageComponent),
    title: 'Admin portal',
    children: []
  },*/
  {
    path: 'portal',
    component: PortalPageComponent,
    title: 'Portal',
    canActivate: [authUserLoggedResolver],
    runGuardsAndResolvers: 'pathParamsChange',
    resolve: {
      sidebar: userSidebarItems
    },
    data: {
      hideFooter: true
    },
    children: portalRoutes
  },
  {
    path: 'admin',
    title: 'Admin Panel',
    component: PortalPageComponent,
    canActivate: [authAdminResolver],
    runGuardsAndResolvers: 'pathParamsChange',
    resolve: {
      sidebar: adminSidebarItems
    },
    data: {
      hideFooter: true
    },
    children: adminRoutes
  },

  {path: '**', component: NotFoundPageComponent, title: '404 - not found'},
];
