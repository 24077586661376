import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {IStartupData, IStartupStatusesResponse, IStartupStatusTrack} from "./api.types";
import {inject} from "@angular/core";
import {APIService} from "./api.service";
import {of} from "rxjs";

export const resolveStartupData: ResolveFn<IStartupData> = (route: ActivatedRouteSnapshot) => {
  const slug = route.paramMap.get('startup');
  const api = inject(APIService);
  if (slug) {
    return api.getStartup(slug)
      .then(data => {
        parseAddress(data);
        return data;
      });
  }
  return of({} as IStartupData);
}

export const resolveStartupTracks: ResolveFn<IStartupStatusTrack[]> = (route: ActivatedRouteSnapshot) => {
  const slug = route.paramMap.get('startup');
  const api = inject(APIService);
  if (slug) {
    return api.getStartupStatuses(slug)
      .then(resp => {
        return resp.tracks;
      });
  }
  return of([]);
}


function parseAddress(data: IStartupData) {
  if (!data.address) {
    return;
  }

  const a = data.address;
  a.text = ((a.line1 || '') + ' - ' + (a.line2 || '')) || '';
}
