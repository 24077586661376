import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router, RouterOutlet} from '@angular/router';
import {FooterComponent} from "./layout/footer/footer.component";
import {IModalData, ModalService} from "./services/modal.service";
import {ModalComponent} from "./controls/modal/modal.component";
import {NgIf} from "@angular/common";
import {ExternalHeaderComponent} from "../oex-ui-kit/components/external-header/external-header.component";
import {AuthService} from "./services/auth.service";
import {ProgressBarComponent} from "../oex-ui-kit/components/progress-bar/progress-bar.component";
import {HeaderComponent, IMenu} from "../oex-ui-kit/components/header/header";
import {MetaService} from "../oex-ui-kit/services/meta.service";
import {APIService} from "./services/api.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, ModalComponent, NgIf, ExternalHeaderComponent, ProgressBarComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) header!: HeaderComponent;
  @ViewChild(RouterOutlet, {static: true}) routerOutlet!: RouterOutlet;
  title = 'Startup Hub';
  isFooter = false;
  modals: IModalData[] = [];
  menuItems: IMenu[] = [
    {
      id: 'profile',
      title: 'Profile',
      showAvatar: true,
      hideOnMobile: true,
      condition: () => this.auth.isLogged,
      items: [
        {
          title: 'InterSystems Startup Portal',
          url: '/portal/overview'
        }
      ],
      buttons: [
        {
          id: 'logout',
          title: 'Log out',
          condition: () => this.auth.isLogged,
          click: () => this.doLogout()
        }
      ]
    }
  ];

  constructor(private modal: ModalService,
              public auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private api: APIService,
              private meta: MetaService,
              private cd: ChangeDetectorRef) {
  }

  get avatar() {
    return this.api.getFileDownloadUrl(this.auth.user.avatar);
  }

  ngOnInit() {
    this.meta.initialize();
    this.updateMenu();
    this.router.events.subscribe(e => {
      if (e instanceof ActivationEnd) {
        if ((e.snapshot.routeConfig?.data as any)?.hideFooter) {
          this.checkFooterVisibility(false);
        } else {
          this.checkFooterVisibility(true);
        }
      }
    });
    this.modal.onModalsChanged.subscribe(modals => {
      this.modals = modals;
      this.cd.detectChanges();
    });
  }

  updateMenu() {
    // Update user name
    const profile = this.menuItems.find(m => m.id === 'profile');
    if (profile) {
      profile.titleDesc = this.auth.user?.name || '';
      profile.items = [
        {
          title: 'Overview',
          url: '/portal/overview',
          condition: () => !!this.auth.user.startup
        },
        {
          title: 'Edit profile',
          url: '/portal/profile',
          condition: () => !!this.auth.user.startup
        },
        {
          title: 'Edit startup',
          url: '/portal/edit',
          condition: () => !!this.auth.user.startup
        },
        {
          title: 'Log Out',
          style: 'link',
          separator: true,
          condition: () => this.auth.isLogged,
          click: () => this.doLogout()
        }
      ];
    }

    /*const portal = profile?.items?.find(m => m.text === 'InterSystems Startup Portal');
    if (portal) {
      portal.url = `/portal/edit/${this.auth.user?.startup?.slug}`;
    }*/
    this.header.refresh();
  }

  onRegister() {
    this.auth.register();
  }

  onLogin() {
    void this.auth.login();
  }

  private checkFooterVisibility(visible: boolean) {
    if (visible !== this.isFooter) {
      this.isFooter = visible;
      this.cd.detectChanges();
    }
  }

  private doLogout() {
    this.auth.logout()
      .then(() => {
        this.updateMenu();
        this.cd.detectChanges();
        // Check for portal or admin panel
        const r = this.router.url.split('/')[1];
        if (r === 'admin' || r === 'portal') {
          void this.router.navigateByUrl('/');
        }
      });

  }
}
