import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, Sanitizer} from '@angular/core';
import {SlicePipe} from "@angular/common";
import {UISize} from "../../base/types";

@Component({
  selector: 'ui-avatar',
  standalone: true,
  imports: [
    SlicePipe
  ],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {
  @Input() avatar = '';
  @Input() name = '';
  @Input() size: UISize = 'small';

  @HostBinding('class.size-xs')
  get isSizeXs() {
    return this.size === 'xs';
  }

  @HostBinding('style.background-image')
  public background: string | null = '';


  constructor(private san: Sanitizer) {
  }

  ngOnInit() {
    this.background = `url(${this.avatar})`;
  }
}
